import React, { Component } from 'react'
import { Link } from 'gatsby'
import {
    Container,
    Row,
    Col
} from 'reactstrap'
import logo from 'images/logo.svg'

class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false
        }

        this.toggleNav = this.toggleNav.bind(this)
    }

    toggleNav() {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        })
    }

    render() {

        const { isNavOpen } = this.state

        return (
            <Container fluid className={`nav-wrap ${isNavOpen ? 'nav-wrap-active' : ''}`}>
                <Row className="justify-content-between align-items-center content-wrap">
                    <Col xs="auto">
                        <Link to="/" className="d-block">
                            <img src={logo} alt="Michael Rose" className="nav-logo"/>
                        </Link>
                    </Col>
                    <Col xs="auto">
                       <button 
                            className={`nav-toggler ${isNavOpen ? 'nav-toggler-active' : ''}`} 
                            type="button" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                            onClick={this.toggleNav}
                        >
                            <span></span>
                        </button>
                        <nav className={`main-nav ${isNavOpen ? 'main-nav-open' : ''}`}>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-us/">about us</Link>
                                </li>
                                <li>
                                    <Link to="/productions/">productions</Link>
                                </li>
                                <li>
                                    <Link to="/contact/">contact</Link>
                                </li> 
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default Navigation