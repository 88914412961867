import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby";
import SEO from 'components/Seo'
import Header from 'components/Header'
import Footer from 'components/Footer'
import CookieConsent from "react-cookie-consent";
import 'stylesheets/main.scss'

class Layout extends Component {
	render() {

		const { title, metaDescription } = this.props.data

		return (
			<div className="site-wrap animated fadeIn">
				<SEO
					title={title}
					description={metaDescription}
				/>
				<CookieConsent
				location="bottom"
				disableStyles={true}
				buttonText="Continue"
				cookieName="bcookie"
				containerClasses="cookie"
				contentClasses="cookieContent"
				buttonClasses="btn"
				expires={150}
>
				<p>We use cookies to help make this website better. For more information on cookies and how we use
								them, please see our <Link className="link link--pink" to="/cookie/">cookies page</Link>.<br/>
								Otherwise, we’ll assume you’re OK to continue.</p>
				</CookieConsent>
				<Header data={this.props.data} />
				{this.props.children}
				<Footer />
			</div>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
	data: {
		title: ""
	}
};

export default Layout
