import React, { Component } from 'react'
import Navigation from 'components/Navigation'

class Header extends Component {
    render() {
        return (
            <>
                <Navigation />
            </>
        )
    }
}

export default Header