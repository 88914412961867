import React, { Component } from 'react'
import { Link } from "gatsby"
// import PropTypes from "prop-types"
// import React from "react"
import { Container, Row, Col } from 'reactstrap'


class Footer extends Component {
  render() {
    return (
      
      <footer className="footer" id="footer">
          <Container className="footer__inner">
              <Row className="justify-content-center align-item-top">
                  <Col lg={12}>
                      <Row>
                          <Col md={6} className="text-md-left">
                              <p className="text-sm ml-2 mt-1">© 2019 Michael Rose Organisation Limited. All rights reserved</p>
                          </Col>
                          <Col md={6} className="text-center text-md-right">

                              <ul className="footer__nav">
                                 {/*  <li>
                                      <Link to="/terms/">Terms & Conditions</Link>
                                  </li> */}
                                  <li>
                                      <Link to="/privacy/">Privacy Policy</Link>
                                  </li>
                                                                        
                                  <li>
                                      <Link to="/cookie/">Cookies</Link>
                                  </li>
                                  
                              </ul>
                          </Col>
                      </Row>
                  </Col>
              </Row>
          </Container>
          <link href="//fast.fonts.net/t/1.css?apiType=css&projectid=73a9fbf0-ea2a-4e35-9f41-c3436a1ab32b" rel="stylesheet"/>

      </footer>
    
      
    )
  }

}

export default Footer


